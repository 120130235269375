import React from "react";

const SvgsIcon = ({ type, ...rest }: any) => {
  switch (type) {
    case "phone-svg":
      return (
        <svg
          {...rest}
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
        >
          <path
            d="M21.6987 18.1439C21.6987 18.5181 21.6155 18.9027 21.4388 19.2768C21.2621 19.651 21.0335 20.0044 20.7321 20.337C20.2228 20.8982 19.6615 21.3036 19.0275 21.5634C18.4039 21.8232 17.7283 21.9584 17.0008 21.9584C15.9407 21.9584 14.8078 21.7089 13.6125 21.1996C12.4173 20.6903 11.222 20.0044 10.0372 19.1417C8.84192 18.2687 7.70903 17.3021 6.62811 16.2315C5.55758 15.1506 4.59098 14.0177 3.72832 12.8329C2.87606 11.648 2.19009 10.4632 1.6912 9.2887C1.19231 8.10384 0.942871 6.97095 0.942871 5.89003C0.942871 5.18327 1.06759 4.5077 1.31704 3.88409C1.56648 3.25008 1.96143 2.66805 2.51229 2.14837C3.17747 1.49358 3.90501 1.17139 4.67413 1.17139C4.96515 1.17139 5.25617 1.23375 5.516 1.35847C5.78623 1.48319 6.02528 1.67027 6.21237 1.9405L8.62366 5.33917C8.81074 5.59901 8.94585 5.83806 9.03939 6.06672C9.13294 6.28498 9.1849 6.50324 9.1849 6.70072C9.1849 6.95016 9.11215 7.19961 8.96664 7.43866C8.83153 7.67771 8.63405 7.92715 8.38461 8.17659L7.5947 8.99768C7.48037 9.11201 7.4284 9.24712 7.4284 9.41342C7.4284 9.49657 7.4388 9.56932 7.45959 9.65247C7.49077 9.73562 7.52195 9.79798 7.54273 9.86034C7.72982 10.2033 8.05201 10.6502 8.50933 11.1907C8.97703 11.7312 9.47592 12.282 10.0164 12.8329C10.5776 13.3837 11.1181 13.893 11.6689 14.3607C12.2094 14.818 12.6563 15.1298 13.0097 15.3169C13.0617 15.3377 13.124 15.3689 13.1968 15.4001C13.2799 15.4312 13.3631 15.4416 13.4566 15.4416C13.6333 15.4416 13.7684 15.3793 13.8828 15.265L14.6727 14.4854C14.9325 14.2256 15.1819 14.0281 15.421 13.9034C15.66 13.7579 15.8991 13.6851 16.1589 13.6851C16.3564 13.6851 16.5643 13.7267 16.7929 13.8203C17.0216 13.9138 17.2606 14.0489 17.5205 14.2256L20.9607 16.6681C21.231 16.8552 21.418 17.0734 21.5324 17.3333C21.6363 17.5931 21.6987 17.8529 21.6987 18.1439Z"
            stroke="currentColor"
            stroke-width="1.55902"
            stroke-miterlimit="10"
          />
        </svg>
      );
    case "video-svg":
      return (
        <svg
          {...rest}
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="26"
          viewBox="0 0 25 26"
          fill="none"
        >
          <path
            d="M13.0757 21.3357H6.507C3.22266 21.3357 2.13135 19.153 2.13135 16.96V8.20869C2.13135 4.92435 3.22266 3.83304 6.507 3.83304H13.0757C16.36 3.83304 17.4513 4.92435 17.4513 8.20869V16.96C17.4513 20.2443 16.3496 21.3357 13.0757 21.3357Z"
            stroke="currentColor"
            stroke-width="1.55902"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.3406 17.8846L17.4512 15.8579V9.29956L20.3406 7.27283C21.7541 6.28545 22.9181 6.88827 22.9181 8.62399V16.5438C22.9181 18.2795 21.7541 18.8824 20.3406 17.8846Z"
            stroke="currentColor"
            stroke-width="1.55902"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.0051 11.5447C12.8661 11.5447 13.5641 10.8467 13.5641 9.98567C13.5641 9.12464 12.8661 8.42664 12.0051 8.42664C11.144 8.42664 10.446 9.12464 10.446 9.98567C10.446 10.8467 11.144 11.5447 12.0051 11.5447Z"
            stroke="currentColor"
            stroke-width="1.55902"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    case "search-svg":
      return (
        <svg
          {...rest}
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
        >
          <path
            d="M14.9871 5.28934H21.2232"
            stroke="currentColor"
            stroke-width="1.55902"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.9871 8.40731H18.1051"
            stroke="currentColor"
            stroke-width="1.55902"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22.2628 12.045C22.2628 17.5015 17.8455 21.9188 12.3889 21.9188C6.93237 21.9188 2.51514 17.5015 2.51514 12.045C2.51514 6.58839 6.93237 2.17116 12.3889 2.17116"
            stroke="currentColor"
            stroke-width="1.55902"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M23.3018 22.9583L21.2231 20.8796"
            stroke="currentColor"
            stroke-width="1.55902"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    case "dots-svg":
      return (
        <svg
          {...rest}
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="21"
          viewBox="0 0 6 21"
          fill="none"
        >
          <circle cx="2.97549" cy="2.17063" r="2.03897" fill="currentColor" />
          <circle cx="2.97549" cy="10.3264" r="2.03897" fill="currentColor" />
          <circle cx="2.97549" cy="18.4825" r="2.03897" fill="currentColor" />
        </svg>
      );
    case "smiley-svg":
      return (
        <svg
          {...rest}
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
        >
          <path
            d="M16.3117 28.7339C23.0682 28.7339 28.5455 23.2566 28.5455 16.5C28.5455 9.7435 23.0682 4.26624 16.3117 4.26624C9.55515 4.26624 4.07788 9.7435 4.07788 16.5C4.07788 23.2566 9.55515 28.7339 16.3117 28.7339Z"
            stroke="currentColor"
            stroke-width="2.03897"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.7241 15.4805C12.5686 15.4805 13.2533 14.7959 13.2533 13.9513C13.2533 13.1067 12.5686 12.4221 11.7241 12.4221C10.8795 12.4221 10.1948 13.1067 10.1948 13.9513C10.1948 14.7959 10.8795 15.4805 11.7241 15.4805Z"
            fill="currentColor"
          />
          <path
            d="M20.8993 15.4805C21.7439 15.4805 22.4286 14.7959 22.4286 13.9513C22.4286 13.1067 21.7439 12.4221 20.8993 12.4221C20.0548 12.4221 19.3701 13.1067 19.3701 13.9513C19.3701 14.7959 20.0548 15.4805 20.8993 15.4805Z"
            fill="currentColor"
          />
          <path
            d="M21.6131 19.5585C21.0742 20.487 20.3009 21.2576 19.3705 21.7933C18.4401 22.329 17.3854 22.611 16.3118 22.611C15.2382 22.611 14.1835 22.329 13.2531 21.7933C12.3228 21.2576 11.5494 20.487 11.0105 19.5585"
            stroke="currentColor"
            stroke-width="2.03897"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    case "paperclip-svg":
      return (
        <svg
          {...rest}
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="33"
          viewBox="0 0 34 33"
          fill="none"
        >
          <path
            d="M21.0134 10.3831L10.398 21.1769C10.0466 21.5647 9.85773 22.0729 9.87061 22.5961C9.8835 23.1194 10.0971 23.6176 10.4672 23.9877C10.8373 24.3578 11.3355 24.5714 11.8588 24.5843C12.382 24.5972 12.8902 24.4084 13.278 24.0569L25.9324 11.2242C26.6353 10.4484 27.0129 9.43212 26.9871 8.38565C26.9614 7.33917 26.5342 6.34268 25.794 5.60248C25.0538 4.86229 24.0573 4.43508 23.0108 4.40932C21.9643 4.38355 20.948 4.7612 20.1723 5.46407L7.51795 18.2968C6.37219 19.4426 5.72852 20.9966 5.72852 22.6169C5.72852 24.2372 6.37219 25.7912 7.51795 26.937C8.6637 28.0827 10.2177 28.7264 11.838 28.7264C13.4584 28.7264 15.0123 28.0827 16.1581 26.937L26.6205 16.5"
            stroke="currentColor"
            stroke-width="2.03897"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );

    case "bar-svg":
      return (
        <svg
          {...rest}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
          />
        </svg>
      );
    case "microphone-svg":
      return (
        <svg
          {...rest}
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="35"
          viewBox="0 0 34 35"
          fill="none"
        >
          <path
            d="M17.3708 22.3502C20.4334 22.3502 22.914 19.8696 22.914 16.807V9.1851C22.914 6.12248 20.4334 3.64191 17.3708 3.64191C14.3082 3.64191 11.8276 6.12248 11.8276 9.1851V16.807C11.8276 19.8696 14.3082 22.3502 17.3708 22.3502Z"
            stroke="currentColor"
            stroke-width="2.0787"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.76929 14.2433V16.5991C6.76929 22.4472 11.5226 27.2005 17.3706 27.2005C23.2187 27.2005 27.972 22.4472 27.972 16.5991V14.2433"
            stroke="currentColor"
            stroke-width="2.0787"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.4443 9.78104C16.6916 9.32372 18.0496 9.32372 19.2969 9.78104"
            stroke="currentColor"
            stroke-width="2.0787"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.262 12.719C16.9964 12.525 17.7586 12.525 18.4931 12.719"
            stroke="currentColor"
            stroke-width="2.0787"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.3706 27.2006V31.358"
            stroke="currentColor"
            stroke-width="2.0787"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    case "plus-svg":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.75 1.5C8.75 1.08579 8.41421 0.75 8 0.75C7.58579 0.75 7.25 1.08579 7.25 1.5V7.75H1C0.585786 7.75 0.25 8.08579 0.25 8.5C0.25 8.91421 0.585786 9.25 1 9.25H7.25V15.5C7.25 15.9142 7.58579 16.25 8 16.25C8.41421 16.25 8.75 15.9142 8.75 15.5V9.25H15C15.4142 9.25 15.75 8.91421 15.75 8.5C15.75 8.08579 15.4142 7.75 15 7.75H8.75V1.5Z"
            fill="currentColor"
          />
        </svg>
      );
    case "import-svg":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.5 1.25C8.5 0.835786 8.16421 0.5 7.75 0.5C7.33579 0.5 7 0.835786 7 1.25L7 9.0856L4.28236 6.34704C3.99059 6.05303 3.51572 6.05121 3.22171 6.34298C2.92769 6.63474 2.92587 7.10961 3.21764 7.40363L7.34471 11.5625C7.48552 11.7044 7.67716 11.7842 7.87707 11.7842C8.07698 11.7842 8.26861 11.7044 8.40943 11.5625L12.5365 7.40363C12.8283 7.10962 12.8264 6.63475 12.5324 6.34298C12.2384 6.05121 11.7635 6.05303 11.4718 6.34704L8.5 9.34169V1.25ZM1.5 14.8926L1.5 10.5691C1.5 10.1549 1.16421 9.81912 0.75 9.81912C0.335786 9.81912 0 10.1549 0 10.5691V14.9315C0 14.9458 0.000410999 14.9601 0.00123182 14.9744C0.0277035 15.4358 0.262368 15.8413 0.608772 16.1148C0.94773 16.3824 1.37517 16.5122 1.79876 16.4991L14.2014 16.4991C14.6249 16.512 15.0522 16.3821 15.391 16.1146C15.7373 15.8413 15.9719 15.4361 15.9987 14.975C15.9996 14.9605 16 14.946 16 14.9315V10.5691C16 10.1549 15.6642 9.81911 15.25 9.81911C14.8358 9.81911 14.5 10.1549 14.5 10.5691V14.8922L14.4993 14.8937L14.4973 14.8979C14.4929 14.906 14.4827 14.9206 14.4616 14.9373C14.4167 14.9727 14.3387 15.0034 14.2437 14.9997C14.2339 14.9993 14.2241 14.9991 14.2143 14.9991L1.78571 14.9991C1.77576 14.9991 1.76581 14.9993 1.75586 14.9997C1.66092 15.0035 1.58301 14.9728 1.53822 14.9374C1.51714 14.9208 1.50703 14.9063 1.50267 14.8983C1.50142 14.896 1.50057 14.8941 1.5 14.8926Z"
            fill="currentColor"
          />
        </svg>
      );
    case "export-svg":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.5 1.25C8.5 0.835786 8.16421 0.5 7.75 0.5C7.33579 0.5 7 0.835786 7 1.25L7 9.0856L4.28236 6.34704C3.99059 6.05303 3.51572 6.05121 3.22171 6.34298C2.92769 6.63474 2.92587 7.10961 3.21764 7.40363L7.34471 11.5625C7.48552 11.7044 7.67716 11.7842 7.87707 11.7842C8.07698 11.7842 8.26861 11.7044 8.40943 11.5625L12.5365 7.40363C12.8283 7.10962 12.8264 6.63475 12.5324 6.34298C12.2384 6.05121 11.7635 6.05303 11.4718 6.34704L8.5 9.34169V1.25ZM1.5 14.8926L1.5 10.5691C1.5 10.1549 1.16421 9.81912 0.75 9.81912C0.335786 9.81912 0 10.1549 0 10.5691V14.9315C0 14.9458 0.000410999 14.9601 0.00123182 14.9744C0.0277035 15.4358 0.262368 15.8413 0.608772 16.1148C0.94773 16.3824 1.37517 16.5122 1.79876 16.4991L14.2014 16.4991C14.6249 16.512 15.0522 16.3821 15.391 16.1146C15.7373 15.8413 15.9719 15.4361 15.9987 14.975C15.9996 14.9605 16 14.946 16 14.9315V10.5691C16 10.1549 15.6642 9.81911 15.25 9.81911C14.8358 9.81911 14.5 10.1549 14.5 10.5691V14.8922L14.4993 14.8937L14.4973 14.8979C14.4929 14.906 14.4827 14.9206 14.4616 14.9373C14.4167 14.9727 14.3387 15.0034 14.2437 14.9997C14.2339 14.9993 14.2241 14.9991 14.2143 14.9991L1.78571 14.9991C1.77576 14.9991 1.76581 14.9993 1.75586 14.9997C1.66092 15.0035 1.58301 14.9728 1.53822 14.9374C1.51714 14.9208 1.50703 14.9063 1.50267 14.8983C1.50142 14.896 1.50057 14.8941 1.5 14.8926Z"
            fill="currentColor"
          />
        </svg>
      );
    case "arrow-down":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
        >
          <path
            d="M1 1L7 7L13 1"
            stroke="#525256"
            stroke-width="2"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    case "logo-w-svg":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="28"
          viewBox="0 0 32 28"
          fill="none"
        >
          <path
            d="M19.5869 18.9811C19.5869 21.9969 17.1421 24.4417 14.1263 24.4417C11.1104 24.4417 8.66563 21.9969 8.66563 18.9811C8.66563 15.9652 11.1104 13.5204 14.1263 13.5204C17.1421 13.5204 19.5869 15.9652 19.5869 18.9811Z"
            fill="#EBEBEF"
          />
          <path
            d="M25.1021 3.74591C22.3063 2.47904 9.43012 7.94874 3.47803 10.5717C6.15374 9.75258 16.6382 7.24072 21.7165 7.24072C25.1021 6.91305 26.8495 18.5988 28.4877 23.9503C28.7062 22.5305 27.898 5.01278 25.1021 3.74591Z"
            fill="#EBEBEF"
          />
        </svg>
      );
    case "logo-b-svg":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 42 42"
          fill="none"
        >
          <rect width="42" height="42" rx="21" fill="#1E1E1E" />
          <path
            d="M24.8476 26.3428C24.8476 29.5776 22.2253 32.2 18.9904 32.2C15.7556 32.2 13.1332 29.5776 13.1332 26.3428C13.1332 23.1079 15.7556 20.4856 18.9904 20.4856C22.2253 20.4856 24.8476 23.1079 24.8476 26.3428Z"
            fill="white"
          />
          <path
            d="M30.7634 10.0012C27.7645 8.64233 13.9532 14.5093 7.56885 17.3227C10.4389 16.4441 21.6847 13.7498 27.1319 13.7498C30.7634 13.3984 32.6377 25.9328 34.3949 31.6729C34.6292 30.15 33.7623 11.3601 30.7634 10.0012Z"
            fill="white"
          />
        </svg>
      );
    case "notification-svg":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
        >
          <path
            d="M21.3333 20V13.0667C20.6667 13.2 20 13.3333 19.3333 13.3333H18.6667V21.3333H5.33333V12C5.33333 8.26667 8.26667 5.33333 12 5.33333C12.1333 3.6 12.9333 2.13333 14 0.933333C13.6 0.4 12.8 0 12 0C10.5333 0 9.33333 1.2 9.33333 2.66667V3.06667C5.33333 4.26667 2.66667 7.86667 2.66667 12V20L0 22.6667V24H24V22.6667L21.3333 20ZM9.33333 25.3333C9.33333 26.8 10.5333 28 12 28C13.4667 28 14.6667 26.8 14.6667 25.3333H9.33333ZM24 6C24 8.53333 21.8667 10.6667 19.3333 10.6667C16.8 10.6667 14.6667 8.53333 14.6667 6C14.6667 3.46667 16.8 1.33333 19.3333 1.33333C21.8667 1.33333 24 3.46667 24 6Z"
            fill="#BBBFCE"
          />
          <path
            d="M24 6C24 8.57733 21.9107 10.6667 19.3333 10.6667C16.756 10.6667 14.6667 8.57733 14.6667 6C14.6667 3.42267 16.756 1.33333 19.3333 1.33333C21.9107 1.33333 24 3.42267 24 6Z"
            fill="#3276FA"
          />
        </svg>
      );
    case "message-add":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M21.3332 2.66667H10.6665C5.33317 2.66667 2.6665 5.33333 2.6665 10.6667V28C2.6665 28.7333 3.2665 29.3333 3.99984 29.3333H21.3332C26.6665 29.3333 29.3332 26.6667 29.3332 21.3333V10.6667C29.3332 5.33333 26.6665 2.66667 21.3332 2.66667Z"
            stroke="#1E1E1E"
            stroke-width="0.89375"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.3335 16H20.6668"
            stroke="#1E1E1E"
            stroke-width="0.89375"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 20.6667V11.3333"
            stroke="#1E1E1E"
            stroke-width="0.89375"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    default:
      return <></>;
  }
};

export default SvgsIcon;
