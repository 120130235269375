"use client";
import React, { useState } from "react";
import Dashboard from "./dashboard/page";
import { Drawer, Menu } from "antd";
import { Header } from "antd/es/layout/layout";
import MainHeader from "./components/navigation";
import SvgsIcon from "./components/svg-icon";

const items = [
  {
    label: "Overview",
    key: "1",
  },
];

const Page = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Header className="!bg-dark-100 !flex !items-center !h-auto lg:!px-12 !px-6 !py-4 !sticky !top-0">
        <MainHeader />
      </Header>
      <section className="bg-light-100 lg:pr-12 lg:pl-5 px-6 py-4 flex justify-between items-center">
        <div className="flex justify-left w-full">
          <div className="lg:block hidden">
            <Menu
              className="nav-bar nav-bar-m"
              items={items}
              selectedKeys={["1"]}
            />
          </div>
          <div className="lg:hidden flex">
            <div>
              <div className="text-black !cursor-pointer" onClick={showDrawer}>
                <SvgsIcon type="bar-svg" />
              </div>
              <Drawer
                title={false}
                placement="left"
                onClose={onClose}
                open={open}
                className="!bg-light-100 !text-white !cursor-pointer drawer-nav-m"
              >
                <Menu className="nav-bar" items={items} selectedKeys={["1"]} />
              </Drawer>
            </div>
          </div>
        </div>
      </section>
      <Dashboard />
    </React.Fragment>
  );
};

export default Page;
